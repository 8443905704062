jQuery(document).ready(function ($) {
    const selectDateInput = document.getElementById('date-to-register-booking-time') || null;
    if (selectDateInput !== null) {
        new tempusDominus.TempusDominus(selectDateInput, {
            display: {
                buttons: {
                    today: true,
                    close: true,
                },
                keepOpen: false,
            },
            hooks: {
                inputFormat: (context, date) => {
                    const innerDate = new Date(date);
                    return ("00" + innerDate.getDate()).slice(-2) + "-" + ("00" + (innerDate.getMonth() + 1)).slice(-2) + "-" + innerDate.getFullYear() + " " + ("00" + innerDate.getHours()).slice(-2) + ":" + ("00" + innerDate.getMinutes()).slice(-2);
                }
            }
        });
    }

    jQuery('.date-field input').each(function () {
        this.setAttribute('onfocusin', '(this.type="date")')
    });

    jQuery(".spinner-on-click").click(function () {
        var buttonText = jQuery(this).text();
        jQuery(this).html('<div class="spinner-border spinner-border-sm" role="status"></div>&nbsp;' + buttonText);
    });

    jQuery('body').on('click', (e) => {
        e.stopPropagation();
        if ($('#main-menu-content').hasClass('show')) {
            if (!jQuery(e.target).closest('#main-menu-content').length && !jQuery(e.target).is('#main-menu-content')) {
                $('#main-menu-content').removeClass('show');
            }
        }
        if ($('#main-sidebar').hasClass('show')) {
            if (!jQuery(e.target).closest('#main-sidebar').length && !jQuery(e.target).is('#main-sidebar')) {
                $('#main-sidebar').removeClass('show');
            }
        }

    })
});

window.toast = (message) => {
    var toast_success = document.getElementById('message_Toast');
    var toas_message = toast_success.querySelector('.body-message');
    toas_message.innerHTML = message
    jQuery('#message_Toast').toast('show');
}

window.toast_error = (message) => {
    var toast_success = document.getElementById('message_Toast_Error');
    var toas_message = toast_success.querySelector('.body-message');
    toas_message.innerHTML = message
    jQuery('#message_Toast_Error').toast('show');
}


/**
 * @param id
 * @param buttonId
 */
window.expand_or_contract = function (id, buttonId) {
    const expandedItem = document.getElementById(id);
    const expandButton = document.getElementById(buttonId);
    if (!expandedItem.classList.contains('active')) {
        expandedItem.classList.add('active');
        expandButton.innerHTML = `<span>Collapse Example <svg enable-background="new 0 0 242.133 242.133" version="1.1"
                                     viewBox="0 0 242.13 242.13" xml:space="preserve"
                                     xmlns="http://www.w3.org/2000/svg"><path
                                        d="m227.13 83.033c8.283 0 15-6.716 15-15v-53.033c0-8.284-6.717-15-15-15h-53.033c-8.284 0-15 6.716-15 15s6.716 15 15 15h16.82l-69.854 69.854-69.853-69.854h16.82c8.284 0 15-6.716 15-15s-6.716-15-15-15h-53.033c-8.283 0-15 6.716-15 15v53.033c0 8.284 6.717 15 15 15 8.285 0 15-6.716 15-15v-16.82l69.854 69.854-69.854 69.853v-16.82c0-8.284-6.715-15-15-15-8.283 0-15 6.716-15 15v53.033c0 8.284 6.717 15 15 15h53.033c8.284 0 15-6.716 15-15s-6.716-15-15-15h-16.82l69.854-69.854 69.854 69.854h-16.821c-8.284 0-15 6.716-15 15s6.716 15 15 15h53.033c8.283 0 15-6.716 15-15v-53.033c0-8.284-6.717-15-15-15-8.285 0-15 6.716-15 15v16.82l-69.854-69.854 69.854-69.854v16.82c0 8.285 6.715 15.001 15 15.001z"/></svg></span>
        `;
    } else {
        expandedItem.removeAttribute('style');
        expandedItem.classList.remove('active');
        expandButton.innerHTML = `<span>View Example <svg enable-background="new 0 0 242.133 242.133" version="1.1"
                                     viewBox="0 0 242.13 242.13" xml:space="preserve"
                                     xmlns="http://www.w3.org/2000/svg"><path
                                        d="m227.13 83.033c8.283 0 15-6.716 15-15v-53.033c0-8.284-6.717-15-15-15h-53.033c-8.284 0-15 6.716-15 15s6.716 15 15 15h16.82l-69.854 69.854-69.853-69.854h16.82c8.284 0 15-6.716 15-15s-6.716-15-15-15h-53.033c-8.283 0-15 6.716-15 15v53.033c0 8.284 6.717 15 15 15 8.285 0 15-6.716 15-15v-16.82l69.854 69.854-69.854 69.853v-16.82c0-8.284-6.715-15-15-15-8.283 0-15 6.716-15 15v53.033c0 8.284 6.717 15 15 15h53.033c8.284 0 15-6.716 15-15s-6.716-15-15-15h-16.82l69.854-69.854 69.854 69.854h-16.821c-8.284 0-15 6.716-15 15s6.716 15 15 15h53.033c8.283 0 15-6.716 15-15v-53.033c0-8.284-6.717-15-15-15-8.285 0-15 6.716-15 15v16.82l-69.854-69.854 69.854-69.854v16.82c0 8.285 6.715 15.001 15 15.001z"/></svg></span>`;
    }
}


window.getFormData = ($form) => {
    let unindexed_array = $form.serializeArray();
    let indexed_array = {};

    jQuery.map(unindexed_array, function (n, i) {
        if (n['name'].endsWith('[]')) {
            let slice = n['name'].slice(0, -2);
            if (!indexed_array[slice]) {
                indexed_array[slice] = [n['value']];
            } else {
                indexed_array[slice].push(n['value'])
            }
        } else {
            indexed_array[n['name']] = n['value'];
        }
    });

    return indexed_array;
}

/**
 *
 */
window.generateRandomString = (length) => {
    /**
     * @type {string}
     */
    let result = '';

    /**
     * @type {string}
     */
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < (length || 8); i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}
